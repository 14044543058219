import React from "react";
import { CgWorkAlt } from "react-icons/cg";
import { FaReact } from "react-icons/fa";
import {
  SiExpo,
  SiFirebase,
  SiFramer,
  SiGit,
  SiGithub,
  SiHtml5,
  SiJavascript,
  SiMongodb,
  SiNextdotjs,
  SiPython,
  SiReact,
  SiTailwindcss,
  SiTypescript,
  SiUnrealengine,
  SiPytorch,
  SiTensorflow,
  SiPostgresql,
  SiKeras
} from "react-icons/si";
import { LuGraduationCap } from "react-icons/lu";
import instagramClonImg from "@/public/instagram-clon.png";
import renderWebImg from "@/public/render-web.png";
import interactiveRenderImg from "@/public/interactive-render.png";
import multimodalWebImg from "@/public/milestone.jpg";

const en = {
  links: [
    {
      name: "Home",
      hash: "#home",
    },
    {
      name: "About",
      hash: "#about",
    },
    {
      name: "Projects",
      hash: "#projects",
    },
    {
      name: "Skills",
      hash: "#skills",
    },
    {
      name: "Experience",
      hash: "#experience",
    },
    {
      name: "LLMVisualizer",
      hash: "/llmpage",
    },
  ],
  intro: {
    greeting: "Hello! I'm Wangpeng An.",
    heading: "Full Stack Machine Learning Developer",
    main: "I am a machine learning engineer with 9 years of experience, specializing in large language models, computer vision and speech recognition. ",
    occupation:
      "My expertise includes developing NLP applications and computer vision/speech systems from ml, backend and frontend.",
    contactButton: "Contact me here",
    downloadCV: "Download CV",
  },
  about: {
    title: "About Me",
    first:
      "I am an experienced machine learning engineer, specializing in large language models and  computer vision with 9 years in the field. My skill set includes Python, TensorFlow, and PyTorch. I have a proven track record in developing innovative solutions in natural language processing and computer vision.",
    second:
      "Beyond my technical expertise, I am known for my problem-solving abilities and eagerness to embrace new challenges. My hobbies, which include music, playing instruments, gaming, and exploring the latest in tech and AI, provide me with a unique blend of creativity and technical insight."
  },
  projects: {
    title: "My Projects",
    data: [
      {
        title: "Multimodal LLM",
        description:
            "Develop Multimodal Large Language Model (LLM) showcases capabilities beyond traditional text responses. It's equipped with features like image and voice recognition, enabling it to interact in various modes. Designed to be highly responsive, it adapts effortlessly across different devices. The model supports multiple languages, enhancing its global accessibility. Additionally, it includes user-friendly interfaces with light and dark modes, smooth animations, and robust form validation for a seamless user experience.",
        tags: ["LLM", "Multimodal", "Vision", "Speech"],
        imageUrl: multimodalWebImg,
        repositoryUrl: "https://arxiv.org/abs/2308.04126",
        demoType: "Deploy",
        demoUrl: "https://github.com/wanghao-cst/Omni-VideoAssistant",
      },
      {
        title: "Computer Vision",
        description:
          "A sophisticated application demonstrating advanced computer vision techniques, such as object detection, image classification, and real-time video processing. It integrates machine learning models to analyze and interpret visual data from the world.",
        tags: ["Machine Learning", "OpenCV", "Python"],
        imageUrl: instagramClonImg,
        repositoryUrl: "",
        demoType: "Video",
        demoUrl: "#",
      },
      {
        title: "Deep Learning Optimization",
        description:
          "A website developed from scratch with responsive design to effectively showcase high-quality architectural renders. It includes a real-time online quotation system, animations, and form validation.",
        tags: ["TensorFlow", "Keras", "CUDA"],
        imageUrl: renderWebImg,
        repositoryUrl: "",
        demoType: "Deploy",
        demoUrl: "#",
      },
      {
        title: "Image Restoration",
        description:
          "An innovative solution for restoring and enhancing old or damaged images using state-of-the-art image processing algorithms. It includes features like noise reduction, color correction, and detail enhancement to bring new life to old photographs.",
        tags: ["Image Processing", "GANs", "Python"],
        imageUrl: interactiveRenderImg,
        repositoryUrl:
          "",
        demoType: "Video",
        demoUrl: "#",
      },
    ],
  },
  skills: {
    title: "My Skills",
    data: [
      {
        skill: "Python",
        icon: React.createElement(SiPython),
        color: "#3572A5",
      },
      {
        skill: "Pytorch",
        icon: React.createElement(SiPytorch),
        color: "#EE4C2C", // Bright Orange for PyTorch
      },

      {
        skill: "TensorFlow",
        icon: React.createElement(SiTensorflow),
        color: "#FF6F00", // Light Blue for TensorFlow
      },
      {
        skill: "Keras",
        icon: React.createElement(SiKeras),
        color: "#D00000", // Light Blue for TensorFlow
      },

      {
        skill: "HTML",
        icon: React.createElement(SiHtml5),
        color: "#e34f26",
      },
      {
        skill: "CSS",
        icon: React.createElement(SiHtml5),
        color: "#264de4",
      },
      {
        skill: "JavaScript",
        icon: React.createElement(SiJavascript),
        color: "#f0db4f",
      },
      {
        skill: "TypeScript",
        icon: React.createElement(SiTypescript),
        color: "#3178c6",
      },
      {
        skill: "React",
        icon: React.createElement(SiReact),
        color: "#61dafb",
      },
      {
        skill: "Next.js",
        icon: React.createElement(SiNextdotjs),
        color: "#000000",
      },
      {
        skill: "Tailwind",
        icon: React.createElement(SiTailwindcss),
        color: "#38bdf8",
      },
      {
        skill: "Framer Motion",
        icon: React.createElement(SiFramer),
        color: "#000000",
      },
      {
        skill: "React Native",
        icon: React.createElement(SiReact),
        color: "#61dafb",
      },
      {
        skill: "Firebase",
        icon: React.createElement(SiFirebase),
        color: "#ffcb2b",
      },
      {
        skill: "MongoDB",
        icon: React.createElement(SiMongodb),
        color: "#4db33d",
      },
    {
        skill: "PostgreSQL",
        icon: React.createElement(SiPostgresql),
        color: "#336791",
    },

      {
        skill: "Git",
        icon: React.createElement(SiGit),
        color: "#f05032",
      },
      {
        skill: "GitHub",
        icon: React.createElement(SiGithub),
        color: "#000000",
      },
    ],
  },
  experience: {
    title: "My Experience",
    data: [
      {
        title: "Tiktok Inc.",
        location: "San Jose, California, USA",
        description:
          "Multimodal-LLM for video understanding.",
        icon: React.createElement(LuGraduationCap),
        date: "September 2021 - Present",
      },
      {
        title: "Meta Platforms Inc.",
        location: "Melon Park, California, USA",
        description:
          "Computer vision engineer in FAIR (Facebook AI Research), worked on the fairness and explainability in AI.",
        icon: React.createElement(LuGraduationCap),
        date: "December 2020 - August 2021",
      },
      {
        title: "AiFi Inc.",
        location: "Santa Clara, California, USA",
        description:
          "Developed aifi-pose system",
        icon: React.createElement(LuGraduationCap),
        date: "July 2018 - December 2020",
      },
      {
        title: "The Hong Kong Polytechnic University",
        location: "Kowloon, Hong Kong",
        description:
          "Developed a new deep learning optimizer based on the pid optimizer.",
        icon: React.createElement(LuGraduationCap),
        date: "October 2016 - April 2018",
      },
      {
        title: "Tsinghua University",
        location: "Beijing, China",
        description:
          "Worked on image denoise, image super-resolution, face detection, face alignment, face gender recognition, face smiling detection, face appearance age estimation, face re-identification, faster-rcnn and generative adversarial networks.",
        icon: React.createElement(LuGraduationCap),
        date: "August 2015 - July 2018",
      },
      {
        title: "Mitsubishi Electric Corporation",
        location: "Hiroshima, Japan",
        description:
          "Concentration on the control system improvement of the steel milling machine.",
        icon: React.createElement(LuGraduationCap),
        date: "January 2013 - March 2013",
      },
      {
        title: "Taiyuan iron and steel corporation",
        location: "Taiyuan, Shanxi Province, China",
        description:
          "Concentration on the hyperparameter tuning for a real giant machine in order to make it learning.",
        icon: React.createElement(FaReact),
        date: "August 2012 - September 2014",
      },
      {
        title: "Kunming University of Science and Technology",
        location: "Kunming, Yunnan Province, China",
        description:
          "major in instrument and measurement science, concentration on the theory of pid controller",
        icon: React.createElement(LuGraduationCap),
        date: "September 2008 - July 2012",
      },
    ],
  },
  contact: {
    title: "Contact Me",
    message:
      "Don't hesitate to get in touch! I'm excited about new job opportunities. You can do so through this form, by sending me a direct email, or by connecting with me on LinkedIn or GitHub. Looking forward to connecting!",
    placeholderEmail: "Your email",
    placeholderMessage: "Your message",
    buttonSubmit: "Submit",
    buttonCopy: "Copy Email Address",
    toastSent: "Email sent successfully!",
    toastCopied: "Email address copied to clipboard!",
  },
  footer: {
    text: "All rights reserved.",
    subtitle: "About this website",
    about:
      "built with React, Next.js, TypeScript, Tailwind CSS, Framer Motion, Vercel hosting.",
  },
};

export default en;
