import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/about.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/experience.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/footer.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/header.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/intro.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/particles-container.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/projects.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/section-divider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/skills.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/theme-switch.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/context/active-section-context.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/context/i18n-context.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/context/theme-context.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-hot-toast/dist/index.mjs")